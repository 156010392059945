import React from 'react'
import { Link } from "react-router-dom";
import LatestBlogs from '../home/LatestBlogs';



const Blog = () => {
    return (
        <>

            <div className=''>
                <section className="page-title" >
                    <div className="content" style={{ backgroundImage: 'url(./assets/public/website/assets/images/background/blogs.jpg)' }}>
                    </div>
                    <ul className="page-breadcrumb">
                        <li><Link to="/">home</Link></li>
                        <li>Our Blog</li>
                    </ul>
                </section>
            </div>
            <LatestBlogs />
            <section className="">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="news-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <a to="blog_details/plywood-furniture-care-guide.html">
                                        <img src="./assets/public/storage/blogs/June2024/LDgvokzuhdKvYOEzpCoY.jpg" alt />
                                    </a>
                                </div>
                                <div className="lower-content">
                                    <div className="post-date">2024-06-30</div>
                                    <h4 className="content_headingtext"><a to="/">Plywood Furniture Care Guide: Expert Tips from Adarsh Sales Pvt. Ltd</a></h4>
                                </div>
                            </div>
                        </div>
                        <div className="news-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <a to="blog_details/which-brand-of-plywood-is-best.html">
                                        <img src="./assets/public/storage/blogs/June2024/1P0w5FhRpgMIBWstn9bb.jpg" alt />
                                    </a>
                                </div>
                                <div className="lower-content">
                                    <div className="post-date">2024-06-26</div>
                                    <h4 className="content_headingtext"><a to="blog_details/which-brand-of-plywood-is-best.html">Which brand of plywood
                                        is best?</a></h4>
                                </div>
                            </div>
                        </div>
                        <div className="news-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <a to="">
                                        <img src="./assets/public/storage/blogs/May2024/GeaXwyLBHJYGUUZ1AWbw.jpg" alt />
                                    </a>
                                </div>
                                <div className="lower-content">
                                    <div className="post-date">2024-04-23</div>
                                    <h4 className="content_headingtext"><a to="">Luxury Bedroom Interior Design in India - Adarsh Sales Pvt. Ltd</a></h4>
                                </div>
                            </div>
                        </div>


                        <div className="news-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <a to="">
                                        <img src="./assets/public/storage/blogs/May2024/OtvoPnoReIqkVYl0dXD3.jpg" alt />
                                    </a>
                                </div>
                                <div className="lower-content">
                                    <div className="post-date">2024-03-29</div>
                                    <h4 className="content_headingtext"><a to="">Which ply is
                                        best for home?</a></h4>
                                </div>
                            </div>
                        </div>
                        <div className="news-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <a to="blog_details/plywood-strongest.html">
                                        <img src="./assets/public/storage/blogs/May2024/jutRVNyDb9Zr4w3LRW6Q.jpg" alt />
                                    </a>
                                </div>
                                <div className="lower-content">
                                    <div className="post-date">2024-03-26</div>
                                    <h4 className="content_headingtext"><a to="blog_details/plywood-strongest.html">What
                                        plywood is strongest?</a></h4>
                                </div>
                            </div>
                        </div>
                        <div className="news-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <a to="blog_details/marine-vs-exterior-plywood-best-project.html">
                                        <img src="./assets/public/storage/blogs/May2024/0GtW0mI58NxUe1eCwkRH.jpg" alt />
                                    </a>
                                </div>
                                <div className="lower-content">
                                    <div className="post-date">2024-04-21</div>
                                    <h4 className="content_headingtext"><a to="blog_details/marine-vs-exterior-plywood-best-project.html">Marine vs.
                                        Exterior Plywood: Which is Best for Your Project?</a></h4>
                                </div>
                            </div>
                        </div>
                        <div className="news-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <a to="blog_details/type-plywood-best-outdoor-use-in-india.html">
                                        <img src="./assets/public/storage/blogs/May2024/mdKq52XHQeNR87yoJI6q.jpg" alt />
                                    </a>
                                </div>
                                <div className="lower-content">
                                    <div className="post-date">2024-04-21</div>
                                    <h4 className="content_headingtext"><a to="blog_details/type-plywood-best-outdoor-use-in-india.html">What Type of
                                        Plywood is Best For Outdoor Use in India ?</a></h4>
                                </div>
                            </div>
                        </div>
                        <div className="news-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <a to="blog_details/best-plywood-company-in-india.html">
                                        <img src="./assets/public/storage/blogs/May2024/G8mrV3VHBi1aiqKljtWZ.jpg" alt />
                                    </a>
                                </div>
                                <div className="lower-content">
                                    <div className="post-date">2024-04-12</div>
                                    <h4 className="content_headingtext"><a to="blog_details/best-plywood-company-in-india.html">Best Plywood in India -
                                        Adarsh Sales Pvt. Ltd</a></h4>
                                </div>
                            </div>
                        </div>
                        <div className="news-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <a to="blog_details/ultimate-guide-choosing-best-plywood-wardrobe-sylvanply-perspective.html">
                                        <img src="./assets/public/storage/blogs/May2024/qqBTjWFYvY0enboumFa9.jpg" alt />
                                    </a>
                                </div>
                                <div className="lower-content">
                                    <div className="post-date">2024-04-05</div>
                                    <h4 className="content_headingtext"><a to="blog_details/ultimate-guide-choosing-best-plywood-wardrobe-sylvanply-perspective.html">The
                                        Ultimate Guide to Choosing the Best Plywood for Your Wardrobe: A  Adarsh Sales Pvt. Ltd
                                        Perspective</a></h4>
                                </div>
                            </div>
                        </div>






                    </div>
                </div>
            </section>


        </>
    )
}

export default Blog