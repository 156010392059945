import React from 'react'

const Abcd = () => {
    return (
        <>

            <div>
                <div className="page-wrapper">
                    <div className="loader-wrap">
                        <div className="preloader">
                            <div className="preloader-close">x</div>
                            <div id="handle-preloader" className="handle-preloader">
                                <div className="animation-preloader">

                                    <img src="./assets/public/website/assets/images/site-loader.gif" className="pageloader_design" />
                                </div>
                            </div>
                        </div>
                    </div>


                    <header className="main-header header-style-two">

                        <div className="header-top-two">
                            <div className="auto-container">
                                <div className="inner-container clearfix">

                                    <div className="top-left clearfix">
                                        <div className="button-box">
                                            <a className="theme-btn contact-btn" href="getquote.html">Get a quote</a>
                                        </div>
                                        <ul className="info-links">
                                            <li><span className="icon flaticon-call" /> Phone: <a href="tel:+91 90736-85001">+91
                                                90736-85001</a></li>
                                            <li><span className="icon flaticon-email-2" />Email: <a href="mailto:reach@sylvanply.com">reach@sylvanply.com</a></li>
                                        </ul>
                                    </div>
                                    <div className="top-right pull-right clearfix">
                                        <ul className="social-box">
                                            <li className="follow">Follow us:</li>
                                            <li>
                                                <a href="https://www.facebook.com/SylvanPlyOfficial/" target="_blank">
                                                    <i className="fab fa-facebook-f" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/sylvanply/" target="_blank">
                                                    <i className="fab fa-instagram" />
                                                </a>
                                            </li>

                                            <li>
                                                <a href="https://www.youtube.com/channel/UCt2pWfJJ4JPDA-PaaE5CbiA" target="_blank">
                                                    <i className="fab fa-youtube" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.linkedin.com/signup/cold-join?session_redirect=https%3A%2F%2Fwww%2Elinkedin%2Ecom%2Fsearch%2Fresults%2Fall%2F%3Fkeywords%3Dsylvan%2520ply%26origin%3DGLOBAL_SEARCH_HEADER%26sid%3DG%2540*&trk=login_reg_redirect" target="_blank">
                                                    <i className="fab fa-linkedin-in" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="header-upper">
                            <div className="auto-container">
                                <div className="clearfix">
                                    <div className="pull-left logo-box">
                                        <div className="logo">
                                            <a href="index.htm">
                                                <img src="./assets/public/website/assets/images/sticky-header.jpg" alt title />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="nav-outer clearfix">

                                        <div className="mobile-nav-toggler"><span className="icon flaticon-menu" /></div>
                                        <nav className="main-menu navbar-expand-md">
                                            <div className="navbar-header">
                                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                                    <span className="icon-bar" />
                                                    <span className="icon-bar" />
                                                    <span className="icon-bar" />
                                                </button>
                                            </div>
                                            <div className="navbar-collapse collapse clearfix" id="navbarSupportedContent">
                                                <ul className="navigation clearfix">
                                                    <li className="current">
                                                        <a href="index.htm">Home</a>
                                                    </li>
                                                    <li className="dropdown ">
                                                        <a href="#">
                                                            About Us
                                                            <i className="fas fa-angle-down icon_angledowndesign" />
                                                        </a>
                                                        <ul>
                                                            <div className="hgfst_sectionx">
                                                                <li>
                                                                    <a href="sylvan-guarantee.html">
                                                                        The Sylvan Guarantee
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="our-journey.html">
                                                                        Our Journey
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="manufacturing-process.html">
                                                                        The Process
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="management.html">
                                                                        Management
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="accreditation.html">
                                                                        Accreditations
                                                                    </a>
                                                                </li>
                                                            </div>
                                                            <div className="hgfst_sectionx">
                                                                <li>
                                                                    <a href="our-national-presence.html">
                                                                        Our National Presence
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="industries-served.html">
                                                                        Industries Served
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="environmental-sustainability.html">
                                                                        Environmental Sustainability
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="privacy-policy.html">
                                                                        Privacy Policy
                                                                    </a>
                                                                </li>
                                                            </div>
                                                            <div className="jkfdrimg_structure">
                                                                <img src="./assets/public/website/assets/images/header-image1.jpg" className="ikuimg_deseign" />
                                                            </div>
                                                        </ul>
                                                    </li>
                                                    <li className="dropdown thememobile_menu ">
                                                        <a href="#">
                                                            Products
                                                            <i className="fas fa-angle-down icon_angledowndesign" />
                                                        </a>
                                                        <ul>
                                                            <li className="dropdown">
                                                                <a href="products/plywood-%26-blockboards.html">
                                                                    Plywood &amp; Blockboards
                                                                </a>
                                                                <ul>
                                                                    <li><a href="product/sylvan-z-premium-ply.html">Z+ Premium Plus</a>
                                                                    </li>
                                                                    <li><a href="product/z-premium.html">Z+ Premium</a>
                                                                    </li>
                                                                    <li><a href="product/elite-plus.html">ELITE PLUS</a>
                                                                    </li>
                                                                    <li><a href="product/premium-club.html">Premium Club</a>
                                                                    </li>
                                                                    <li><a href="product/oceanic-premium-club.html">Oceanic Premium Club</a>
                                                                    </li>
                                                                    <li><a href="product/robusta-premium-plus.html">Robusta Premium Plus</a>
                                                                    </li>
                                                                    <li><a href="product/robusta-premium.html">Robusta Premium</a>
                                                                    </li>
                                                                    <li><a href="product/primo-plus.html">Primo Plus</a>
                                                                    </li>
                                                                    <li><a href="product/robusta.html">Robusta</a>
                                                                    </li>
                                                                    <li><a href="product/blu-marine.html">BLU Marine</a>
                                                                    </li>
                                                                    <li><a href="product/blu-bwr.html">BLU BWR</a>
                                                                    </li>
                                                                    <li><a href="product/blu-mr.html">BLU MR</a>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                            <li className="dropdown">
                                                                <a href="products/modular.html">
                                                                    Modular Segment
                                                                </a>
                                                                <ul>
                                                                    <li><a href="product/modular-premium-plywood.html">Modular Premium Plywood</a>
                                                                    </li>
                                                                    <li><a href="product/modular-primo-ply.html">Modular Primo Ply</a>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                            <li className="dropdown">
                                                                <a href="products/flush-door.html">
                                                                    Flush Door
                                                                </a>
                                                                <ul>
                                                                    <li><a href="product/gateway-pine-fd.html">Gateway Pine FD</a>
                                                                    </li>
                                                                    <li><a href="product/gateway-hardwood-fd.html">Gateway Hardwood FD</a>
                                                                    </li>
                                                                    <li><a href="product/robusta-mix-pine-fd.html">Robusta Mix Pine FD</a>
                                                                    </li>
                                                                </ul>
                                                            </li>

                                                            <li className="dropdown">
                                                                <a href="products/distinct-product.html">
                                                                    Distinct Products Distinct Product
                                                                </a>
                                                                <ul>
                                                                    <li><a href="product/elasto-flexi-ply.html">Elasto Flexi Ply</a>
                                                                    </li>
                                                                    <li><a href="product/shuttering-ply-builder.html">Shuttering Ply</a>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                            <li>
                                                                <a href="sylvan-panel.html">Sylvan Panel</a>
                                                            </li>
                                                            <li>
                                                                <a href="products/timber.html">Timber</a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li className="dropdown mymenu_section">
                                                        <a href="#">
                                                            Products
                                                            <i className="fas fa-angle-down icon_angledowndesign" />
                                                        </a>
                                                        <ul className="sidebgtx_area3">
                                                            <div className="uproduct_outsectionxz">
                                                                <a href="products/plywood-%26-blockboards.html">
                                                                    <h2 className="pandbtext_design">
                                                                        Plywood &amp; Blockboards
                                                                    </h2>
                                                                </a>
                                                                <li><a href="product/sylvan-z-premium-ply.html">Z+ Premium Plus</a>
                                                                </li>
                                                                <li><a href="product/z-premium.html">Z+ Premium</a>
                                                                </li>
                                                                <li><a href="product/elite-plus.html">ELITE PLUS</a>
                                                                </li>
                                                                <li><a href="product/premium-club.html">Premium Club</a>
                                                                </li>
                                                                <li><a href="product/oceanic-premium-club.html">Oceanic Premium Club</a>
                                                                </li>
                                                                <li><a href="product/robusta-premium-plus.html">Robusta Premium Plus</a>
                                                                </li>
                                                                <li><a href="product/robusta-premium.html">Robusta Premium</a>
                                                                </li>
                                                                <li><a href="product/primo-plus.html">Primo Plus</a>
                                                                </li>
                                                                <li><a href="product/robusta.html">Robusta</a>
                                                                </li>
                                                                <li><a href="product/blu-marine.html">BLU Marine</a>
                                                                </li>
                                                                <li><a href="product/blu-bwr.html">BLU BWR</a>
                                                                </li>
                                                                <li><a href="product/blu-mr.html">BLU MR</a>
                                                                </li>
                                                                <a href="products/modular.html">
                                                                    <h2 className="pandbtext_design">
                                                                        Modular Segment
                                                                    </h2>
                                                                </a>
                                                                <li><a href="product/modular-premium-plywood.html">Modular Premium Plywood</a>
                                                                </li>
                                                                <li><a href="product/modular-primo-ply.html">Modular Primo Ply</a>
                                                                </li>

                                                            </div>
                                                            <div className="uproduct_outsectionxz">

                                                                <a href="products/flush-door.html">
                                                                    <h2 className="pandbtext_design">
                                                                        Flush Door
                                                                    </h2>
                                                                </a>
                                                                <li><a href="product/gateway-pine-fd.html">Gateway Pine FD</a>
                                                                </li>
                                                                <li><a href="product/gateway-hardwood-fd.html">Gateway Hardwood FD</a>
                                                                </li>
                                                                <li><a href="product/robusta-mix-pine-fd.html">Robusta Mix Pine FD</a>
                                                                </li>
                                                                <a href="products/distinct-product.html">
                                                                    <h2 className="pandbtext_design">
                                                                        Distinct Products
                                                                    </h2>
                                                                </a>
                                                                <li><a href="product/elasto-flexi-ply.html">Elasto Flexi Ply</a>
                                                                </li>
                                                                <li><a href="product/shuttering-ply-builder.html">Shuttering Ply</a>
                                                                </li>
                                                                <a href="sylvan-panel.html">
                                                                    <h2 className="pandbtext_design">
                                                                        Sylvan Panel
                                                                    </h2>
                                                                </a>
                                                                <a href="products/timber.html">
                                                                    <h2 className="pandbtext_design">
                                                                        Timber
                                                                    </h2>
                                                                </a>
                                                            </div>
                                                            <div className="jkfdrimg_structureproduct">
                                                                <img src="./assets/public/website/assets/images/header-image2.jpg" className="ikuimg_productdeseign" />
                                                            </div>
                                                        </ul>
                                                    </li>
                                                    <li className="dropdown ">
                                                        <a href="#">
                                                            Attributes
                                                            <i className="fas fa-angle-down icon_angledowndesign" />
                                                        </a>
                                                        <ul className="sidebgtx_area2 mainadjustment_area">
                                                            <div className="hgfst_sectionx adjustmenu_area">
                                                                <li>
                                                                    <a href="ezero.html">
                                                                        <span className="czeroformat_text1">E0</span>
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="fireguard-technology.html">
                                                                        Fireguard Technology
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="anti-termite-borer-plywood.html">
                                                                        ATBS
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="plywood-technologies.html">
                                                                        All Superpowers
                                                                    </a>
                                                                </li>
                                                            </div>

                                                        </ul>
                                                    </li>
                                                    <li className="dropdown ">
                                                        <a href="#">
                                                            Tools
                                                            <i className="fas fa-angle-down icon_angledowndesign" />
                                                        </a>
                                                        <ul className="sidebgtx_area2 mainadjustment_area">
                                                            <div className="hgfst_sectionx adjustmenu_area">
                                                                <li>
                                                                    <a href="warranty.html">
                                                                        Warranty Certificate
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="find-a-store.html">
                                                                        Find
                                                                        A
                                                                        store
                                                                    </a>
                                                                </li>


                                                                <li>
                                                                    <a href="ask-a-question.html">
                                                                        Ask
                                                                        A
                                                                        question
                                                                    </a>
                                                                </li>
                                                            </div>

                                                        </ul>
                                                    </li>
                                                    <li className="dropdown ">
                                                        <a href="#">
                                                            More
                                                            <i className="fas fa-angle-down icon_angledowndesign" />
                                                        </a>
                                                        <ul className="sidebgtx_area1">
                                                            <div className="hgfst_sectionx">
                                                                <li>
                                                                    <a href="media.html">
                                                                        Media
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="blogs.html">
                                                                        Blogs
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="faqs.html">
                                                                        FAQs
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="consultation.html">
                                                                        Get consultation
                                                                    </a>
                                                                </li><li>
                                                                    <a href="become-dealer-sub-dealer.html">
                                                                        Become
                                                                        A
                                                                        dealer/ sub dealer
                                                                    </a>
                                                                </li>
                                                            </div>
                                                            <div className="hgfst_sectionx">
                                                                <li>
                                                                    <a href="become-influencer.html">
                                                                        Become
                                                                        AN
                                                                        influencer
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="careers.html">
                                                                        Careers
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="contact.html">
                                                                        Contact Us
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="getquote.html">
                                                                        Get Quote
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="investors.html">
                                                                        Investors
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="terms.html">
                                                                        Terms and Conditions
                                                                    </a>
                                                                </li>
                                                            </div>
                                                            <div className="jkfdrimg_structure">
                                                                <img src="./assets/public/website/assets/images/header-image3.jpg" className="ikuimg_deseign" />
                                                            </div>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </nav>
                                        <div className="outer-box clearfix">


                                            <div className="nav-btn navSidebar-button"><span className="icon flaticon-menu-2" /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="mobile-menu">
                            <div className="menu-backdrop" />
                            <div className="close-btn"><span className="icon flaticon-multiply" /></div>
                            <nav className="menu-box">
                                <div className="nav-logo"><a href="index.htm"><img src="./assets/public/website/assets/images/sticky-header.jpg" alt title /></a>
                                </div>
                                <div className="menu-outer">
                                </div>
                            </nav>
                        </div>
                    </header>

                    <div className="xs-sidebar-group info-group">
                        <div className="xs-overlay xs-bg-black" />
                        <div className="xs-sidebar-widget">
                            <div className="sidebar-widget-container">
                                <div className="widget-heading">
                                    <a href="#" className="close-side-widget">
                                        X
                                    </a>
                                </div>
                                <div className="sidebar-textwidget">
                                    <div className="sidebar-info-contents">
                                        <div className="content-inner">
                                            <div className="content-box">
                                                <h4>About Us</h4>
                                                <p className="text">Sylvan Ply is synonymous with trust and reliability, offering best
                                                    plywood and blockboard in India. With a legacy of over 70 years, we take pride
                                                    in delivering high-performance commercial plywood to our valued customers.</p>
                                                <a href="our-journey.html" className="theme-btn btn-style-one"><span className="txt">Know More</span></a>
                                            </div>
                                            <div className="contact-info">
                                                <h4>Contact Info</h4>
                                                <ul className="list-style-one">
                                                    <li>
                                                        <i className="fas fa-map-marker icon" />
                                                        Adventz Infinity@5 Block – BN5, Office No-802, Sector-V, Saltlake Kolkata
                                                        700091
                                                    </li>
                                                    <li>
                                                        <span className="icon fa fa-phone" />
                                                        +91 90736-85001
                                                    </li>
                                                    <li>
                                                        <span className="icon fa fa-envelope" />reach@sylvanply.com
                                                    </li>
                                                </ul>
                                            </div>
                                            <ul className="social-box">
                                                <li>
                                                    <a href="https://www.facebook.com/SylvanPlyOfficial/" target="_blank">
                                                        <i className="fab fa-facebook-f" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.instagram.com/sylvanply/" target="_blank">
                                                        <i className="fab fa-instagram" />
                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="https://www.youtube.com/channel/UCt2pWfJJ4JPDA-PaaE5CbiA" target="_blank">
                                                        <i className="fab fa-youtube" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.linkedin.com/signup/cold-join?session_redirect=https%3A%2F%2Fwww%2Elinkedin%2Ecom%2Fsearch%2Fresults%2Fall%2F%3Fkeywords%3Dsylvan%2520ply%26origin%3DGLOBAL_SEARCH_HEADER%26sid%3DG%2540*&trk=login_reg_redirect" target="_blank">
                                                        <i className="fab fa-linkedin-in" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <a href="https://wa.me/9073685001?text=hello" target="”_blank”" className="whatsapp-btn">
                        <i className="fab fa-whatsapp" />
                    </a>


                    <div className="sbuttons">

                        <a href="getquote.html" target="_blank" className="sbutton whatsapp tooltip" data-tooltip="Get quote" data-tooltip-pos="left" data-tooltip-length="medium">
                            <i className="fas fa-file-alt quic" />
                        </a>
                        <a href="tel:+91 90736-85001" target="_blank" className="sbutton fb tooltip" data-tooltip="Call us" data-tooltip-pos="left" data-tooltip-length="medium">
                            <i className="fas fa-phone-alt quic" />
                        </a>
                        <a href="find-a-store.html" target="_blank" className="sbutton twitt tooltip" data-tooltip="Locate us" data-tooltip-pos="left" data-tooltip-length="medium">
                            <i className="fas fa-map-pin quic" />
                        </a>
                        <a href="#" target="_blank" className="sbutton pinteres tooltip" data-toggle="modal" data-target="#myModal" data-tooltip="Get In Touch" data-tooltip-pos="left" data-tooltip-length="medium">
                            <i className="fas fa-file" />
                        </a>
                        <a target="_blank" className="sbutton mainsbutton"><i className="fas fa-comments" /></a>
                    </div>

                    <div className="modal fade" id="myModal1">
                        <div className="modal-dialog custom_modalqtesection1">
                            <div className="modal-content">

                                <div className="modal-header">
                                    <h4 className="modal-title custitle_textdesign">How can I help you</h4>
                                    <button type="button" className="close sideclose_design" data-dismiss="modal">×</button>
                                </div>

                                <div className="modal-body">
                                    <form method="post" action="https://sylvanply.com/getquotesubmit" id="quoteForm">
                                        <input type="hidden" name="_token" defaultValue="HPOBiUIzAHwmlDIOd3by6CtEQMlQhNBRocUiG7zb" />                            <div className="row">
                                            <div className="col-lg-12">
                                                <div className="rightconsultion_outarea">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-6">
                                                            <div className="sanfld_area">
                                                                <div className="form-group sdfc_frmarea">
                                                                    <label htmlFor="usr" className="sanlabel_txdesign">Name*</label>
                                                                    <input type="text" className="form-control sanfld_lblarea" id="usr" name="name" required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-6">
                                                            <div className="sanfld_area">
                                                                <div className="form-group sdfc_frmarea">
                                                                    <label htmlFor="usr" className="sanlabel_txdesign">Phone Number*</label>
                                                                    <input maxLength={10} type="tel" className="form-control sanfld_lblarea" id="usr" name="phone" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-6">
                                                            <div className="sanfld_area">
                                                                <div className="form-group sdfc_frmarea">
                                                                    <label htmlFor="usr" className="sanlabel_txdesign">Email</label>
                                                                    <input type="email" className="form-control sanfld_lblarea" id="usr" name="email" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-6">
                                                            <div className="sanfld_area">
                                                                <div className="form-group sdfc_frmarea">
                                                                    <label htmlFor="usr" className="sanlabel_txdesign">City</label>
                                                                    <input type="text" className="form-control sanfld_lblarea" id="usr" name="city" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-6">
                                                            <div className="sanfld_area">
                                                                <div className="form-group sdfc_frmarea">
                                                                    <label htmlFor="usr" className="sanlabel_txdesign">Pin code</label>
                                                                    <input maxLength={6} type="number" className="form-control sanfld_lblarea" id="usr" name="pin" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-6">
                                                            <div className="sanfld_area">
                                                                <div className="form-group sdfc_frmarea">
                                                                    <label htmlFor="usr" className="sanlabel_txdesign">Select
                                                                        Category*</label>
                                                                    <select name="category" id="category" className="form-control sanfld_lblarea">
                                                                        <option value="Dealer">Dealer</option>
                                                                        <option value="Sub Dealer">Sub Dealer</option>
                                                                        <option value="Influencer">Influencer</option>
                                                                        <option value="Direct Customer">Direct Customer</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-6">
                                                            <div className="sanfld_area">
                                                                <div className="form-group sdfc_frmarea">
                                                                    <label htmlFor="usr" className="sanlabel_txdesign">Requirement*</label>
                                                                    <select name="requirement" id="requirement" className="form-control sanfld_lblarea">
                                                                        <option value="Ply">Ply</option>
                                                                        <option value="Blockboard">Blockboard</option>
                                                                        <option value="Door">Door</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-6">
                                                            <div className="sanfld_area">
                                                                <div className="form-group sdfc_frmarea">
                                                                    <label htmlFor="usr" className="sanlabel_txdesign">Site Pin code</label>
                                                                    <input maxLength={6} type="number" className="form-control sanfld_lblarea" id="usr" name="site_pin_code" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="formfld_backarea">
                                                                <label className="lattext_design">
                                                                    Is there any construction work going on at your site?
                                                                </label>
                                                                <div className="cntr">
                                                                    <label htmlFor="rdo-1" className="btn-radio">
                                                                        <input type="radio" id="rdo-1" name="isanyconstruction" defaultValue="yes" />
                                                                        <svg width="15px" height="15px" viewBox="0 0 20 20">
                                                                            <circle cx={10} cy={10} r={9} />
                                                                            <path d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z" className="inner" />
                                                                            <path d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z" className="outer" />
                                                                        </svg>
                                                                        <span>Yes</span>
                                                                    </label>
                                                                    <label htmlFor="rdo-2" className="btn-radio">
                                                                        <input type="radio" id="rdo-2" name="isanyconstruction" defaultValue="no" />
                                                                        <svg width="15px" height="15px" viewBox="0 0 20 20">
                                                                            <circle cx={10} cy={10} r={9} />
                                                                            <path d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z" className="inner" />
                                                                            <path d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z" className="outer" />
                                                                        </svg>
                                                                        <span>No</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <input type="hidden" name="sum" defaultValue={13} />
                                                        <div className="col-lg-12 ">
                                                            <div className="sanfld_area">
                                                                <div className="form-group sdfc_frmarea">
                                                                    <div className="row">
                                                                        <div className="col-lg-4">
                                                                            <h5 style={{ color: '#3c3531', fontWeight: 800, fontSize: 13, padding: '11px 12px 7px 25px', border: '1px solid #bdbdbd', marginTop: 1, borderRadius: 4 }}>
                                                                                What is 6 + 7?
                                                                            </h5>
                                                                        </div>
                                                                        <div className="col-lg-8">
                                                                            <div className="input-box">
                                                                                <input type="text" required className="form-control sanfld_lblarea" name="captcha" placeholder="Please verify that you are not a robot" id="captcha" />
                                                                                <span id="message">*</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div className="yuxct_section">
                                                                <p className="pbxc_textdesign">
                                                                    By proceeding, you are authorizing Sylvanply to get in touch
                                                                    with you through calls, sms, or e-mail.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="formsubmit_consultinarea">
                                                        <button type="button" id="submitBtn" className="mtnsbmt_design">Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="myModal">
                        <div className="modal-dialog custom_modalqtesection">
                            <div className="modal-content">

                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal">×</button>
                                </div>

                                <div className="modal-body">
                                    <a href="getquote.html"><button type="button" className="another_btndesign">
                                        GET QUOTE
                                    </button></a>
                                    <a href="consultation.html"><button type="button" className="another_btndesign1">
                                        GET CONSULTATION
                                    </button></a>
                                    <a href="find-a-store.html"><button type="button" className="another_btndesign2">
                                        FIND A STORE
                                    </button></a>
                                    <a href="become-dealer-sub-dealer.html"><button type="button" className="another_btndesign3 anthrdlr_btndesign">
                                        BECOME A DEALER / SUB DEALER
                                    </button></a>
                                    <a href="become-influencer.html"> <button type="button" className="another_btndesign4 anthrdlr_btndesign1">
                                        BECOME AN INFLUENCER
                                    </button></a>
                                </div>

                            </div>
                        </div>
                    </div>
                    <title>Best Plywood &amp; Blockboard Company In India | Sylvanply
                    </title>
                    <meta name="description" content="Best plywood company in india With 70+ Years of Experience that offers premium quality and durable wood products for all your construction and interior needs." />

                    <div id="demo" className="carousel slide sylvanply_sliderarea" data-ride="carousel">
                        <ul className="carousel-indicators">
                            <li data-target="#demo" data-slide-to={0} className="active" />
                            <li data-target="#demo" data-slide-to={1} />
                            <li data-target="#demo" data-slide-to={2} />
                            <li data-target="#demo" data-slide-to={3} />
                            <li data-target="#demo" data-slide-to={4} />
                            <li data-target="#demo" data-slide-to={5} />
                            <li data-target="#demo" data-slide-to={6} />

                        </ul>

                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src="./assets/public/storage/sliders/July2024/IrYgYNAusCqRjh5tU3Lg.jpeg" alt="sylvanply-banne1" />
                            </div>
                            <div className="carousel-item ">
                                <img src="./assets/public/storage/sliders/July2024/DfdGS50XQkEGjC7BMKbV.jpg" alt="sylvanply-banne1" />
                            </div>
                            <div className="carousel-item ">
                                <img src="./assets/public/storage/sliders/July2024/qA8KDC5b8IWWX5vJhQWO.jpg" alt="sylvanply-banne1" />
                            </div>
                            <div className="carousel-item ">
                                <img src="./assets/public/storage/sliders/July2024/Xfos0KcZ3tDjxhrumrLS.jpg" alt="sylvanply-banne1" />
                            </div>
                            <div className="carousel-item ">
                                <img src="./assets/public/storage/sliders/July2024/wZIOCHm95QofkFcuXGWw.jpg" alt="sylvanply-banne1" />
                            </div>
                            <div className="carousel-item ">
                                <img src="./assets/public/storage/sliders/July2024/olk1JId48BcVJJMNtuGv.jpg" alt="sylvanply-banne1" />
                            </div>
                            <div className="carousel-item ">
                                <img src="./assets/public/storage/sliders/July2024/FcxIHHszecRCn2HBUeUD.jpg" alt="sylvanply-banne1" />
                            </div>
                        </div>
                        <a className="carousel-control-prev" href="#demo" data-slide="prev">
                            <span className="carousel-control-prev-icon" />
                        </a>
                        <a className="carousel-control-next" href="#demo" data-slide="next">
                            <span className="carousel-control-next-icon" />
                        </a>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 aboutus_outsection">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <div className="leftimg_outsection">
                                            <img src="./assets/public/website/assets/images/resource/about-us2.jpg" className="abtus_imgdesign" />
                                            <div className="year-box">

                                                <h1 className="ml5">
                                                    <span className="text-wrapper">
                                                        <span className="line line1" />
                                                        <span className="letters letters-left">EXCELLENCE</span>

                                                        <span className="line line2" />
                                                    </span>
                                                </h1>
                                                <div className="count-box">
                                                    <span className="count-text" />
                                                </div>
                                                <span className="years yearstext_design"> 90 Years</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="righttime_outsection">
                                            <div className="banner">

                                                <h1 className="textabtn_Design aus_hallttext">About Us</h1>
                                            </div>
                                            <p className="aboutus_content">
                                            </p><p className="aboutus_content">Sylvan Ply is synonymous with trust and reliability, offering the best plywood and blockboard in India. With a legacy of over 70 years, we take pride in delivering high-performance commercial plywood to our valued customers.</p>
                                            <p className="aboutus_content">Whether you're a builder, architect, interior designer, or homeowner, Sylvan Ply delivers the best plywood you can trust.&nbsp; Our products provide the ideal foundation for projects that demand excellence, durability, and value. Sylvan Ply positions itself as a leading contender amongst the best plywood and blockboard company &nbsp;in India.</p>
                                            <p className="aboutus_content">When you choose Sylvan Ply, you choose a brand synonymous with quality and longevity. Our products are meticulously designed and manufactured to meet the diverse needs of our customers, ensuring durability and performance that surpasses industry standards. Whether you require plywood or blockboard, we have got you covered. Sylvan Ply has earned its position among India’s leading plywood manufacturers. Choose Sylvan Ply, Best Plywood &amp; Blockboard Company in India.</p>
                                            <div className="fact-counter style-three factposition_area">
                                                <div className="row clearfix">

                                                    <div className="column counter-column col-lg-6 col-md-6 col-sm-12 abtfst_gaparea">
                                                        <div className="inner wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                                            <div className="content">
                                                                <div className="habtus_section">
                                                                    <img src="./assets/public/website/assets/images/resource/workplace.gif" className="jhbtus_imgdesign" />
                                                                </div>
                                                                <div className="count-outer count-box alternate">
                                                                    <span className="count-text" data-speed={5000} data-stop={2854}>0</span>+
                                                                </div>
                                                                <h4 className="counter-title cmpltd_prjctstextdesign">
                                                                    Completed Project</h4>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="column counter-column col-lg-6 col-md-6 col-sm-12">
                                                        <div className="inner wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
                                                            <div className="content">
                                                                <div className="habtus_section">
                                                                    <img src="./assets/public/website/assets/images/resource/reactions.gif" className="jhbtus_imgdesign" />
                                                                </div>
                                                                <div className="count-outer count-box">
                                                                    <span className="count-text" data-speed={3000} data-stop={100}>0</span>K+
                                                                </div>
                                                                <h4 className="counter-title cmpltd_prjctstextdesign">
                                                                    Company Followers</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="kmrebtn_area">
                                                <a href="sylvan-guarantee.html" className="theme-btn btn-style-two"><span className="txt">Know More</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="services-section-four product_mainarea">
                        <div className="auto-container">
                            <div className="banner">
                                <h1 className="textabtn_Design">Product Range</h1>
                            </div>
                            <div className="row">
                                <div className="service-block-three col-lg-4 col-md-6 col-sm-12">
                                    <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                        <div className="image">
                                            <a href="#"><img src="./assets/public/website/assets/images/resource/product-range1.jpg" alt /></a>
                                        </div>
                                        <div className="lower-content">
                                            <div className="content">

                                                <h5><a href="#">Plywood</a></h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="service-block-three col-lg-4 col-md-6 col-sm-12">
                                    <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                        <div className="image">
                                            <a href="#"><img src="./assets/public/website/assets/images/resource/product-range2.jpg" alt /></a>
                                        </div>
                                        <div className="lower-content">
                                            <div className="content">

                                                <h5><a href="#">Blockboards</a></h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="service-block-three col-lg-4 col-md-6 col-sm-12">
                                    <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                        <div className="image">
                                            <a href="#"><img src="./assets/public/website/assets/images/resource/product-range33.jpg" alt /></a>
                                        </div>
                                        <div className="lower-content">
                                            <div className="content">

                                                <h5><a href="#">Flush Door</a></h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="productrnge_btnarea">
                                    <div className="kmrebtn_area">
                                        <a href="#" className="theme-btn btn-style-two"><span className="txt"> Browse More</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 newctta_outsection">
                                <div className="row">
                                    <div className="col-lg-6 newctaleft_outsection">
                                        <div className="ctfst">
                                            <h1 className="cfxht_text">
                                                Get An Easy Quotation
                                            </h1>
                                            <div className="button-box btnbox_area">
                                                <a className="btn-style-four theme-btn" href="#" data-toggle="modal" data-target="#myModal"><span className="txt">Click Now</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 newctaright_outsection">
                                        <div className="slice_phnbox">
                                            <span className="iconcf flaticon-call-2" />
                                            Call Us Any Time:<br />
                                            <a href="tel:+91 90736-85001">+91 90736-85001</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="myModal">
                        <div className="modal-dialog custom_modalqtesection">
                            <div className="modal-content">

                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal">×</button>
                                </div>

                                <div className="modal-body">

                                    <button type="button" className="another_btndesign">
                                        GET QUOTE
                                    </button>
                                    <button type="button" className="another_btndesign1">
                                        GET CONSULTATION
                                    </button>
                                    <button type="button" className="another_btndesign2">
                                        FIND A STORE
                                    </button>
                                    <button type="button" className="another_btndesign3 anthrdlr_btndesign">
                                        BECOME A DEALER/ SUB DEALER
                                    </button>
                                    <button type="button" className="another_btndesign4 anthrdlr_btndesign1">
                                        BECOME AN INFLUENCER
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className="container">
                        <section className="ctenewcolorback_outdesign">
                            <div className="auto-container">
                                <div className="row clearfix">

                                    <div className="title-column col-lg-5 col-md-12 col-sm-12">
                                        <div className="inner-column">
                                            <h3 className="exousvb_textdesign">Explore Our Stores</h3>

                                        </div>
                                    </div>
                                    <div className="info-column col-lg-7 col-md-12 col-sm-12">
                                        <form id="myForm" onsubmit="return false;">
                                            <input type="hidden" name="_token" defaultValue="HPOBiUIzAHwmlDIOd3by6CtEQMlQhNBRocUiG7zb" />                            <div className="finput_sectionarea">
                                                <input type="text" id="usr" name="pincode" placeholder="Enter Pincode" className="finput_sectionareadesign" />
                                                <input type="button" defaultValue="Go" onclick="submitForm()" className="finput_sectionareabtndesign" />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>


                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 faqoutarea_section">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="leftfaq_ottutsection">
                                            <video className="faqvideo_responsive" loop="true" autoPlay="autoplay" muted>
                                                <source src="public/website/assets/images/resource/shorts1.mp4" type="video/mp4" />
                                            </video>
                                            <img src="./assets/public/website/assets/images/resource/treelogs.jpg" className="tlogs_tree" />
                                            <div className="shapesarea_section">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="rightfaq_ottoutsection">
                                            <h2 className="sectitle_areaon">
                                                FREQUENTLY ASKED QUESTION
                                            </h2>
                                            <ul className="accordion-box">

                                                <li className="accordion block">
                                                    <div className="acc-btn  wow fadeInDown" data-wow-delay="300ms" data-wow-duration="1500ms">
                                                        <div className="icon-outer"><span className="icon icon-plus fa fa-plus" />
                                                            <span className="icon icon-minus fa fa-minus" />
                                                        </div>
                                                        What is the warranty you get for Sylvan Ply’s Products?
                                                    </div>
                                                    <div className="acc-content fadeInDown" data-wow-delay="300ms" data-wow-duration="1500ms">
                                                        <div className="content">
                                                            <div className="text">
                                                                <p /><p>At Sylvan Ply, you get a lifetime warranty ranging from 250% to 1000% on select premium range products.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>


                                                <li className="accordion block">
                                                    <div className="acc-btn  wow fadeInDown" data-wow-delay="300ms" data-wow-duration="1500ms">
                                                        <div className="icon-outer"><span className="icon icon-plus fa fa-plus" />
                                                            <span className="icon icon-minus fa fa-minus" />
                                                        </div>
                                                        What IS specifications do Sylvan Ply products feature?
                                                    </div>
                                                    <div className="acc-content fadeInDown" data-wow-delay="300ms" data-wow-duration="1500ms">
                                                        <div className="content">
                                                            <div className="text">
                                                                <p /><p>The most important IS specifications that Sylvan Ply’s premium products<br />&nbsp;adhere by are:</p>
                                                                <p>IS: 10701 – BIS Specification for Boiling Water Proof (BWP) Structural Grade Plywood</p>
                                                                <p>IS: 5509 – BIS Specification for Fire-retardant Plywood</p>
                                                                <p>IS: 710 – BIS Specification for BWP Marine Grade Plywood</p>
                                                                <p>IS: 303 – BIS Specification for Moisture-resistant Plywood and Boiling Water Resistant (BWR) Plywood</p>
                                                                <p>IS: 2202 – BIS Specification for Waterproof Flush Doors</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>


                                                <li className="accordion block">
                                                    <div className="acc-btn  wow fadeInDown" data-wow-delay="300ms" data-wow-duration="1500ms">
                                                        <div className="icon-outer"><span className="icon icon-plus fa fa-plus" />
                                                            <span className="icon icon-minus fa fa-minus" />
                                                        </div>
                                                        Why is Sylvan Ply a better option?
                                                    </div>
                                                    <div className="acc-content fadeInDown" data-wow-delay="300ms" data-wow-duration="1500ms">
                                                        <div className="content">
                                                            <div className="text">
                                                                <p /><p>Sylvan Ply is the best Plywood and Blockboard company in India, offering class- leading products with unmatched quality at an affordable price. Our plywood feature:</p>
                                                                <ul>
                                                                    <li>Lifetime Warranty*</li>
                                                                    <li>100% Termite and Borer-resistant</li>
                                                                    <li>100% Boiling Waterproof</li>
                                                                    <li>Zero Gap, Warping or Bending</li>
                                                                    <li>Anti-fungal and Bacterial Coating</li>
                                                                </ul>
                                                                <p><em>* applies to only the Sylvan Exclusive range</em></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>


                                                <li className="accordion block">
                                                    <div className="acc-btn  wow fadeInDown" data-wow-delay="300ms" data-wow-duration="1500ms">
                                                        <div className="icon-outer"><span className="icon icon-plus fa fa-plus" />
                                                            <span className="icon icon-minus fa fa-minus" />
                                                        </div>
                                                        How can I purchase Sylvan plywood?
                                                    </div>
                                                    <div className="acc-content fadeInDown" data-wow-delay="300ms" data-wow-duration="1500ms">
                                                        <div className="content">
                                                            <div className="text">
                                                                <p /><p>You can either walk into your nearest store/ contact your nearest dealer/ interior designer/architector email us with your requirements; our customer care executive will get back to you within 48 hours.&nbsp;For more information, get in touch with us at: reach@sylvanply.com</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <section className="news-section">
                        <div className="auto-container">

                            <div className="banner">
                                <h1 className="textabtn_Design">Latest Blogs</h1>
                            </div>
                            <div className="row clearfix">

                                <div className="news-block col-lg-4 col-md-6 col-sm-12">
                                    <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                        <div className="image">
                                            <a href="blog_details/sylvanply-unique-home-office-design.html">
                                                <img src="./assets/public/storage/blogs/August2024/TvFc9zVfzp2dlXlZIJiA.jpeg" alt />
                                            </a>
                                        </div>
                                        <div className="lower-content">
                                            <div className="post-date">2024-08-27</div>
                                            <h4 className="content_headingtext"><a href="blog_details/sylvanply-unique-home-office-design.html">Unleashing Creativity: SylvanPly's Guide to Unique Home and Office Design</a>
                                            </h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="news-block col-lg-4 col-md-6 col-sm-12">
                                    <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                        <div className="image">
                                            <a href="blog_details/sylvanply-manufacturing-process.html">
                                                <img src="./assets/public/storage/blogs/August2024/7E9HHdBq35V1X2aEqfeC.jpg" alt />
                                            </a>
                                        </div>
                                        <div className="lower-content">
                                            <div className="post-date">2024-08-24</div>
                                            <h4 className="content_headingtext"><a href="blog_details/sylvanply-manufacturing-process.html">SylvanPly's Manufacturing Process: A Journey of Craftsmanship</a>
                                            </h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="news-block col-lg-4 col-md-6 col-sm-12">
                                    <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                        <div className="image">
                                            <a href="blog_details/sylvan-ply-robusta-premium-plus.html">
                                                <img src="./assets/public/storage/blogs/August2024/zIAvO3MhMD0QPouZJI0U.jpg" alt />
                                            </a>
                                        </div>
                                        <div className="lower-content">
                                            <div className="post-date">2024-08-22</div>
                                            <h4 className="content_headingtext"><a href="blog_details/sylvan-ply-robusta-premium-plus.html">Sylvan Ply Robusta Premium Plus: The Pinnacle of Plywood Performance</a>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="button-box text-center">
                                <a className="btn-style-two theme-btn" href="blogs.html"><span className="txt">Browse Our
                                    Blogs</span></a>
                            </div>
                        </div>
                    </section>


                    <section className="testimonial-section">
                        <div className="image-layer" style={{ backgroundImage: 'url(./assets/public/website/assets/images/background/testimonials-back.jpg)' }}>
                        </div>
                        <div className="auto-container">
                            <div className="row clearfix">

                                <div className="title-column col-lg-4 col-md-12 col-sm-12">
                                    <div className="inner-column">
                                        <h2 className="thte_textdesign">The biggest thing <br /> we build is trust.</h2>


                                        <div className="button-box">
                                            <a className="btn-style-one theme-btn" href="industries-served.html"><span className="txt">More About Us</span></a>
                                        </div>
                                    </div>
                                </div>

                                <div className="carousel-column col-lg-8 col-md-12 col-sm-12">
                                    <div className="inner-column">
                                        <div className="single-item-carousel owl-carousel owl-theme">

                                            <div className="testimonial-block">
                                                <div className="inner-box">
                                                    <div className="author-image">
                                                        <img src="./assets/public/storage/testimonials/December2023/afvKJRWYfPLDNM7E2pTY.png" alt />
                                                    </div>
                                                    <div className="text">“While using a product we look forward to longevity, quality, after-sales service, and price. Sylvan gateway flush doors and plywoods meet all the Standards(BIS).”</div>
                                                    <h5>Bijay Jain</h5>
                                                    <div className="designation">Arrjavv Builder Private Limited</div>
                                                </div>
                                            </div>


                                            <div className="testimonial-block">
                                                <div className="inner-box">
                                                    <div className="author-image">
                                                        <img src="./assets/public/storage/testimonials/December2023/szVgyZYCKNPhnjEIVhw8.png" alt />
                                                    </div>
                                                    <div className="text">““SYLVAN PLY”, now a symbolic name in Ply industry who will illuminate the world of ply with the good quality and finish of their product . Specially, ‘Sylvan Z+ Premium’ and ‘Marine Oceanic’ Plywood is better for their intrisic strength and long lasting value.”</div>
                                                    <h5>Abhijit Dhar</h5>
                                                    <div className="designation">Dhar &amp; Associates Pvt. Ltd.</div>
                                                </div>
                                            </div>


                                            <div className="testimonial-block">
                                                <div className="inner-box">
                                                    <div className="author-image">
                                                        <img src="./assets/public/storage/testimonials/December2023/n2ogBzLsA2Tn5iXRPwNu.png" alt />
                                                    </div>
                                                    <div className="text">“I have been using Sylvan Ply for the past 7 years and I have always been satisfied with it.”</div>
                                                    <h5>Anindita Chakraborty</h5>
                                                    <div className="designation">Gestalt Interiors</div>
                                                </div>
                                            </div>


                                            <div className="testimonial-block">
                                                <div className="inner-box">
                                                    <div className="author-image">
                                                        <img src="./assets/public/storage/testimonials/December2023/oSiKryH2RWhhwPMHOUS8.png" alt />
                                                    </div>
                                                    <div className="text">“It has been observed that materials supplied by M/s Sylvan Plyboard (India) Limited to different Bank’s Empanelled contractors is good.”</div>
                                                    <h5>SBI</h5>
                                                    <div className="designation">Manager, Lho Kolkata</div>
                                                </div>
                                            </div>


                                            <div className="testimonial-block">
                                                <div className="inner-box">
                                                    <div className="author-image">
                                                        <img src="./assets/public/storage/testimonials/April2024/yBLE6oBFSquSXuiKiGTe.jpg" alt />
                                                    </div>
                                                    <div className="text">“14 saal ka sammandh,  Quality uss se kafi jayada. Yeh sach hain, break Na banne ka wada. Kafi purani ye dosti meri sylvan ke sath, Ply ho toh Sylvan iss may nahi or koyee baat.”</div>
                                                    <h5>Souvik Banerjee</h5>
                                                    <div className="designation">Interior Designer and Decorator</div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="clients-section outnews_section">
                        <div className="auto-container">


                            <div className="banner">
                                <h1 className="textabtn_Design">OUR CLIENTS</h1>
                            </div>
                            <div className="inner-container">
                                <div className="sponsors-outer">
                                    <ul className="sponsors-carousel owl-carousel owl-theme">
                                        <li className="slide-item">
                                            <figure className="image-box"><a href="#"><img src="./assets/public/website/assets/images/clients/1.png" alt /></a></figure>
                                        </li>
                                        <li className="slide-item">
                                            <figure className="image-box"><a href="#"><img src="./assets/public/website/assets/images/clients/2.png" alt /></a></figure>
                                        </li>
                                        <li className="slide-item">
                                            <figure className="image-box"><a href="#"><img src="./assets/public/website/assets/images/clients/3.png" alt /></a></figure>
                                        </li>
                                        <li className="slide-item">
                                            <figure className="image-box"><a href="#"><img src="./assets/public/website/assets/images/clients/4.png" alt /></a></figure>
                                        </li>
                                        <li className="slide-item">
                                            <figure className="image-box"><a href="#"><img src="./assets/public/website/assets/images/clients/5.png" alt /></a></figure>
                                        </li>
                                        <li className="slide-item">
                                            <figure className="image-box"><a href="#"><img src="./assets/public/website/assets/images/clients/6.png" alt /></a></figure>
                                        </li>
                                        <li className="slide-item">
                                            <figure className="image-box"><a href="#"><img src="./assets/public/website/assets/images/clients/7.png" alt /></a></figure>
                                        </li>
                                        <li className="slide-item">
                                            <figure className="image-box"><a href="#"><img src="./assets/public/website/assets/images/clients/8.png" alt /></a></figure>
                                        </li>
                                        <li className="slide-item">
                                            <figure className="image-box"><a href="#"><img src="./assets/public/website/assets/images/clients/9.png" alt /></a></figure>
                                        </li>
                                        <li className="slide-item">
                                            <figure className="image-box"><a href="#"><img src="./assets/public/website/assets/images/clients/10.png" alt /></a></figure>
                                        </li>
                                        <li className="slide-item">
                                            <figure className="image-box"><a href="#"><img src="./assets/public/website/assets/images/clients/11.png" alt /></a></figure>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="modal fade" id="myModal911">
                        <div className="modal-dialog findstore_modalareadesign">
                            <div className="modal-content">

                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal">×</button>
                                </div>

                                <div className="modal-body">
                                    <form action="https://sylvanply.com/contacts" method="POST">
                                        <input type="hidden" name="_token" defaultValue="HPOBiUIzAHwmlDIOd3by6CtEQMlQhNBRocUiG7zb" />                                                <div className="row">
                                            <div className="col-lg-6 col-6">
                                                <div className="sanfld_area">
                                                    <div className="form-group sdfc_frmarea">
                                                        <label htmlFor="name" className="sanlabel_txdesign">Name*</label>
                                                        <input type="text" className="form-control sanfld_lblarea" defaultValue id="name" name="name" required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-6">
                                                <div className="sanfld_area">
                                                    <div className="form-group sdfc_frmarea">
                                                        <label htmlFor="phone" className="sanlabel_txdesign">Phone Number*</label>
                                                        <input type="text" className="form-control sanfld_lblarea" id="phone" name="phone" required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-6">
                                                <div className="sanfld_area">
                                                    <div className="form-group sdfc_frmarea">
                                                        <label htmlFor="location" className="sanlabel_txdesign">Location*</label>
                                                        <input type="text" className="form-control sanfld_lblarea" id="location" name="location" required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-6">
                                                <div className="sanfld_area">
                                                    <div className="form-group sdfc_frmarea">
                                                        <label htmlFor="address" className="sanlabel_txdesign">Pincode*</label>
                                                        <input type="text" className="form-control sanfld_lblarea" id="pincode" name="pincode" required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="contctbtnarea_sloutr">
                                                <input type="button" defaultValue="Submit" onclick="saveStoreEnquiry()" name className="mtnsbmt_design" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer className="footer-style-two" style={{ backgroundImage: 'url("public/website/assets/images/background/footer-back.jpg")' }}>
                        <div className="auto-container">
                            <div className="widgets-section">
                                <div className="row clearfix">
                                    <div className="big-column col-lg-12">
                                        <div className="row clearfix">

                                            <div className="footer-column col-lg-3">
                                                <div className="footer-widget logo-widget">
                                                    <div className="widget-content">
                                                        <div className="logo">
                                                            <a href="index.htm"><img src="./assets/public/website/assets/images/newsylvan-logo1.png" alt /></a>
                                                        </div>
                                                        <div className="text">Manufacturing plywood blended with reliability and made from world-class raw materials for a bond that lasts forever.</div>
                                                        <ul className="social-box">
                                                            <li>
                                                                <a href="https://www.facebook.com/SylvanPlyOfficial/" className target="_blank">
                                                                    <i className="fab fa-facebook-f" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="https://www.instagram.com/sylvanply/" className target="_blank">
                                                                    <i className="fab fa-instagram" />
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a href="https://www.youtube.com/channel/UCt2pWfJJ4JPDA-PaaE5CbiA" className target="_blank">
                                                                    <i className="fab fa-youtube-square" />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="https://www.linkedin.com/signup/cold-join?session_redirect=https%3A%2F%2Fwww%2Elinkedin%2Ecom%2Fsearch%2Fresults%2Fall%2F%3Fkeywords%3Dsylvan%2520ply%26origin%3DGLOBAL_SEARCH_HEADER%26sid%3DG%2540*&trk=login_reg_redirect" className target="_blank">
                                                                    <i className="fab fa-linkedin-in" />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="footer-column col-lg-2">
                                                <div className="footer-widget links-widget">
                                                    <h5>Quick Links</h5>
                                                    <ul className="list-link">
                                                        <li><a href="our-journey.html">Journey</a></li>
                                                        <li><a href="manufacturing-process.html">Process</a></li>
                                                        <li><a href="management.html">Management</a></li>
                                                        <li><a href="accreditation.html">Accreditations</a></li>
                                                        <li><a href="careers.html">Career</a></li>
                                                        <li><a href="blogs.html">Blogs</a></li>
                                                        <li><a href="terms.html">Terms &amp; Conditions</a></li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="footer-column col-lg-2">
                                                <div className="footer-widget links-widget">
                                                    <h5>Our Products</h5>
                                                    <ul className="list-link">
                                                        <li><a href="products/plywood-%26-blockboards.html">Plywood &amp; Blockboards</a></li>
                                                        <li><a href="products/modular.html">Modular</a></li>
                                                        <li><a href="sylvan-panel.html">Sylvan Panel</a></li>
                                                        <li><a href="products/timber.html">Timber</a></li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="footer-column col-lg-2">
                                                <div className="footer-widget links-widget">
                                                    <h5>Tools</h5>
                                                    <ul className="list-link">
                                                        <li><a href="warranty.html">Warranty Certificate</a></li>
                                                        <li><a href="find-a-store.html">Find a Store</a></li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="footer-column col-lg-3">
                                                <div className="footer-widget links-widget">
                                                    <h5>Contact Us</h5>
                                                    <div className="subscribe-form-two">
                                                        <ul className="footer-widget__contact-list list-unstyled clearfix">
                                                            <li>
                                                                <div className="icon downaddress_icon1">
                                                                    <i className="fas fa-map-marker mapphn_icondesign" />
                                                                </div>
                                                                <div className="text">
                                                                    <h5>Address</h5>
                                                                    <p>
                                                                        Adventz Infinity@5 Block – BN5, Office No-802, Sector-V, Saltlake Kolkata 700091
                                                                    </p>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="icon downaddress_icon2">
                                                                    <i className="fas fa-phone-volume mapphn_icondesign" />
                                                                </div>
                                                                <div className="text">
                                                                    <h5>Call Us</h5>
                                                                    <p>
                                                                        <a href="tel:+91 90736-85001">
                                                                            +91 90736-85001
                                                                        </a>
                                                                    </p>

                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="icon downaddress_icon2">
                                                                    <i className="fas fa-envelope mapphn_icondesign" />
                                                                </div>
                                                                <div className="text">
                                                                    <h5>Send Email</h5>
                                                                    <p>
                                                                        <a href="mailto:reach@sylvanply.com">         reach@sylvanply.com
                                                                        </a>
                                                                    </p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer-bottom">
                            <div className="auto-container">
                                <div className="getconsul_areabtn">
                                    <button className="gcsul_btndesign" data-toggle="modal" data-target="#myModal">
                                        Get Consultation
                                    </button>
                                </div>
                                <div className="copyright">
                                    © 2024 SYLVANPLY . All Rights Reserved
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
                <div className="search-popup">
                    <button className="close-search style-two"><span className="flaticon-cancel-1" /></button>
                    <button className="close-search"><span className="flaticon-up-arrow" /></button>
                    <form method="post" action="https://html.themerange.net/bricks/blog.html">
                        <div className="form-group">
                            <input type="search" name="search-field" defaultValue placeholder="Search Here" required />
                            <button type="submit"><i className="fa fa-search" /></button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Abcd