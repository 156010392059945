import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoMdClose } from 'react-icons/io';
import emailjs from "@emailjs/browser";




const Hadear = () => {
  const [toggle, setToggle] = useState(false);

  const CloseClick = () => {
    setToggle(false);

  };
  const [ismobile, setIsmobile] = useState(0);

  // const { categorytotal } = useSelector((store) => store.categoryAll);


  const [showAbout, setShowAbout] = useState(true);
  const [showInterior, setShowInterior] = useState(true);
  const [showGallery, setShowGallery] = useState(true);

  const closemenu = () => {
    document.getElementById("mytogglebtn").classList.add("collapsed");
    document.getElementById("menu").classList.remove("in");
  };



  const [name, setName] = useState("");
  const [mail, setmail] = useState("");
  const [massages, setMassages] = useState("");
  const [number, setNumber] = useState("");
  const [state, setState] = useState("");
  const [subject, setSubject] = useState("");
  const [city, setCity] = useState("");

  const nameChange = (e) => {
    const value = e.target.value;
    setName(value);
  };
  const stateChange = (e) => {
    const value = e.target.value;
    setState(value);
  };
  const cityChange = (e) => {
    const value = e.target.value;
    setCity(value);
  };
  const subjectChange = (e) => {
    const value = e.target.value;
    setSubject(value);
  };
  const numberChange = (e) => {
    const value = e.target.value;
    setNumber(value);
  };

  const mailChange = (e) => {
    const value = e.target.value;
    setmail(value);
  };
  const massagesChange = (e) => {
    const value = e.target.value;
    setMassages(value);
  };


  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(

        "service_oumku8k",
        "template_esy4tqm",
        "#myForm",
        "8i7i_APkS7sZ9CqPl"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          if (response.text === "OK") {
            setName("");
            setmail("");
            setMassages("");
            setNumber("");
            setState("");
            setSubject("");
            setCity("");

          }
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  };


  return (
    <>
      {/* exter */}

      {/* <div className="loader-wrap">
        <div className="preloader">
          <div className="preloader-close" onClick={closemenu} >x</div>
          <div id="handle-preloader" className="handle-preloader">
            <div className="animation-preloader">
              <img src="./assets/public/website/assets/images/adarshply_logo - Copy.png" className="pageloader_design" />
            </div>
          </div>
        </div>
      </div> */}


      <div className="modal fade" id="myModal1">
        <div className="modal-dialog custom_modalqtesection1">
          <div className="modal-content">

            <div className="modal-header">
              <h4 className="modal-title custitle_textdesign">How can I help you</h4>
              <button type="button" className="close sideclose_design" data-dismiss="modal"> <b style={{ color: "red" }}>X</b></button>
            </div>

            <div className="modal-body">
              <form method="post" onSubmit={sendEmail} id="myForm" >
                {/* <form method="post" action="" id="quoteForm" > */}
                <input type="hidden" name="_token" defaultValue="HPOBiUIzAHwmlDIOd3by6CtEQMlQhNBRocUiG7zb" />                            <div className="row">
                  <div className="col-lg-12">
                    <div className="rightconsultion_outarea">
                      <div className="row">
                        <div className="col-lg-6 col-6">
                          <div className="sanfld_area">
                            <div className="form-group sdfc_frmarea">
                              <label htmlFor="usr" className="sanlabel_txdesign">Name*</label>
                              <input type="text" className="form-control sanfld_lblarea" id="usr"
                                name="from_name"
                                onChange={(e) => nameChange(e)}
                                required
                                value={name}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-6">
                          <div className="sanfld_area">
                            <div className="form-group sdfc_frmarea">
                              <label htmlFor="usr" className="sanlabel_txdesign">Phone Number*</label>
                              <input maxLength={10} type="tel" className="form-control sanfld_lblarea" id="usr"
                                name='phone'
                                onChange={(e) => numberChange(e)}
                                value={number}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-6">
                          <div className="sanfld_area">
                            <div className="form-group sdfc_frmarea">
                              <label htmlFor="usr" className="sanlabel_txdesign">Email</label>
                              <input type="email" className="form-control sanfld_lblarea" id="usr"
                                name='email'
                                onChange={(e) => mailChange(e)}
                                value={mail}
                                required

                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-6">
                          <div className="sanfld_area">
                            <div className="form-group sdfc_frmarea">
                              <label htmlFor="usr" className="sanlabel_txdesign">City</label>
                              <input type="text" className="form-control sanfld_lblarea" id="usr"
                                name="city"
                                onChange={(e) => cityChange(e)}
                                value={city}
                                required
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="yuxct_section">
                            <p className="pbxc_textdesign">
                              By proceeding, you are authorizing Adarsh Sales Pvt. Ltd  to get in touch with you through calls, sms, or e-mail.
                            </p>
                          </div>
                        </div>
                      </div>
                      <a href="">
                        <div className="formsubmit_consultinarea">
                          <button type="button" className="mtnsbmt_design">Submit</button>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <a href="https://wa.me/+919977155591?text=Hello Sir" target="”_blank”" className="whatsapp-btn">
        <i className="fab fa-whatsapp" />
      </a>
      {/* end exter */}
      <header className="header-style-two">
        <div className="header-top-two">
          <div className="auto-container">
            <div className="inner-container clearfix">

              <div className="top-left clearfix">
                <div className="button-box">
                  <Link className="theme-btn contact-btn" to="/contact-us">Get a quote</Link>
                </div>
                <ul className="info-links">
                  <li><span className="icon flaticon-call" /> Phone: <a href="tel:+919977155591">+91
                    9977155591</a></li>
                  <li><span className="icon flaticon-email-2" />Email: <a href="mailto:aspl2009@yahoo.in">aspl2009@yahoo.in</a></li>
                </ul>
              </div>
              <div className="top-right pull-right clearfix">
                <ul className="social-box">
                  <li className="follow">Follow us:</li>
                  <li>
                    <a to="" target="_blank">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a to="" target="_blank">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>

                  <li>
                    <a to="https://www.youtube.com/channel/UCt2pWfJJ4JPDA-PaaE5CbiA" target="_blank">
                      <i className="fab fa-youtube" />
                    </a>
                  </li>
                  <li>
                    <a to="https://www.linkedin.com/signup/cold-join?session_redirect=https%3A%2F%2Fwww%2Elinkedin%2Ecom%2Fsearch%2Fresults%2Fall%2F%3Fkeywords%3Dsylvan%2520ply%26origin%3DGLOBAL_SEARCH_HEADER%26sid%3DG%2540*&trk=login_reg_redirect" target="_blank">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <header className="header-area stricky" style={{ backgroundColor: "white" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="outer-box clearfix">
                <div className="logo">
                  <Link to="/">
                    <img src="./assets/public/website/assets/images/Untitled design (12).png" alt="Awesome Logo" />
                  </Link>
                </div>
                <nav className="main-menu">
                  <div className="navbar-header">
                    <button
                      id="mytogglebtn"
                      v
                      className="navbar-toggle collapsed"
                      data-toggle="collapse"
                      data-target="#menu"
                      onClick={closemenu}
                    >
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />

                    </button>
                  </div>
                  <div className="navbar-collapse collapse clearfix" id="menu">
                    <ul className="navigation clearfix" style={{ fontFamily: "'PT Serif', serif" }} >
                      <li className="current" >
                        <Link to="/" onClick={closemenu}>
                          Home
                        </Link>
                      </li>
                      <li className="" >
                        <Link to="/overview" onClick={closemenu}>
                          Overview
                        </Link>
                      </li>
                      {/* <li className="dropdown">
                        <Link to="/about-us" onClick={closemenu}>About Us</Link>
                        <ul
                          style={
                            showAbout
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <li>
                            <Link to="/about-us" onClick={closemenu}>
                              About Us
                            </Link>
                          </li>

                          <li>
                            <Link to="/Mission" onClick={closemenu}>
                              Vision & Mission
                            </Link>
                          </li>
                        </ul>
                        <div
                          className="dropdown-btn"
                          onClick={() => setShowAbout(!showAbout)}
                        ></div>
                      </li> */}
                      <li className="dropdown">
                        <Link onClick={closemenu}>Our Products</Link>
                        <ul
                          style={
                            showAbout
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >

                          <li>
                            <Link to="/flush-doors" onClick={closemenu}>
                              Flush Doors
                            </Link>
                          </li>
                          <li>
                            <Link to="/plywood" onClick={closemenu}>
                              Plywood
                            </Link>
                          </li>
                          <li>
                            <Link to="/block-boards" onClick={closemenu}>
                              Block Boards
                            </Link>
                          </li>
                        </ul>

                        <div
                          className="dropdown-btn"
                          onClick={() => setShowAbout(!showAbout)}
                        ></div>
                      </li>




                      {/* <li className="">
                        <Link to="/gallery" onClick={closemenu}>
                          Gallery
                        </Link>
                      </li> */}

                      <li className="">
                        <Link to="/blog" onClick={closemenu}>
                          Blog
                        </Link>
                      </li>
                      <li className="">
                        <Link to="/careers" onClick={closemenu}>
                          Careers
                        </Link>
                      </li>

                      <li className="dropdown">
                        <Link onClick={closemenu}>More</Link>
                        <ul
                          style={
                            showAbout
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >

                          <li>
                            <a to="/" onClick={closemenu}>
                              Download
                            </a>
                            <ul
                              style={
                                showAbout
                                  ? { display: "block" }
                                  : { display: "none" }
                              }
                            >

                              <li>
                                <a href="./assets/ply/ASPL COMPANY PROFILE.PDF" target="_blank" onClick={closemenu}>
                                  Company Profile
                                </a>
                              </li>
                              <li>
                                <a href="./assets/ply/WARRANTY T&C.PDF" target="_blank" onClick={closemenu}>
                                  Warranty T&C
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link to="/plywood" onClick={closemenu}>
                              Wood Work
                            </Link>
                            <ul
                              style={
                                showAbout
                                  ? { display: "block" }
                                  : { display: "none" }
                              }
                            >

                              <li>
                                <a href="./assets/ply/ASPL COMPANY PROFILE.PDF" target="_blank" onClick={closemenu}>
                                  Company Profile
                                </a>
                              </li>
                              <li>
                                <a href="./assets/ply/TAKSHANAM_furniture collection-22-23.PDF" target="_blank" onClick={closemenu}>
                                  Furniture Collection
                                </a>
                              </li>
                            </ul>
                          </li>

                        </ul>

                        <div
                          className="dropdown-btn"
                          onClick={() => setShowAbout(!showAbout)}
                        ></div>
                      </li>


                      <li className="">
                        <Link to="/contact-us" onClick={closemenu}>
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>


    </>
  )
}

export default Hadear